
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  
  --primary-main: #023a15;
  --primary-light: #32653c;
  --primary-dark: #000000;

  /* --primary-main: #2a6348;
  --primary-light: #589174;
  --primary-dark: #003820; */

  --secondary-main: #e84710;
  --secondary-light: #ff7a41;
  --secondary-dark: #ae0000;
  
  --common-primary-contrast:#ffffff;
  --common-secondary-contrast:#000000;

  --common-white: #ffffff;
  --common-white-dark: #678973;
  --common-white-contrast-dark: #969696;
  
}

@font-face {
  font-family: 'AvenirBlack';
  src: local('AvenirBlack'),
       url('./assets/fonts/avenir/Avenir-Black.woff') format('woff'),
       url('./assets/fonts/avenir/Avenir-Black.woff') format('woff2');
}

@font-face {
  font-family: 'AvenirLight';
  src: local('AvenirLight'),
       url('./assets/fonts/avenir/Avenir-Light.woff') format('woff'),
       url('./assets/fonts/avenir/Avenir-Light.woff') format('woff2');
}

body {
  margin: 0;
  font-family: 'Oswald', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  /* border: 1px var(--primary-main) solid; */
  /* border-radius: 5px; */
  text-decoration-line: underline; 
}


/* Reference expressive css : https://tailwindcss.com/ */

.bg-primary-main {

  background-color: var(--primary-main);
}

.bg-secondary-main {

  background-color: var(--secondary-main);
}

.text-white {

  color: var(--common-white) !important;
}

.text-primary-main {

  color: var(--primary-main) !important;
}

.text-primary-light {

  color: var(--primary-light) !important;
}

.text-primary-dark {

  color: var(--primary-dark) !important;
}

.text-secondary-main {

  color: var(--secondary-main) !important;
}

.text-secondary-light {

  color: var(--secondary-light) !important;
}

.text-white-dark {

  color: var(--common-white-dark) !important;
}

.text-white-contrast-dark {

  color: var(--common-white-contrast-dark) !important;
}

.text-black {

  color: var(--common-secondary-contrast) !important;
}

.text-oswald {

  font-family: 'Oswald', sans-serif !important;
}

.text-avenir-black {

  font-family: 'AvenirBlack';
}

.text-avenir-light {

  font-family: 'AvenirLight' !important;
}

.text-bold {

  font-weight: bold !important;
}

.text-500 {

  font-weight: 500 !important;
}

.text-88px {

  font-size: 88px !important;
}

.text-56px {

  font-size: 56px !important;  

}

.text-40px {

  font-size: 40px;
}

.text-36px {

  font-size: 36px;
}

.text-20px {

  font-size: 20px !important;
}

.text-small {

  font-size: small !important;
}

.text-lg {

  font-size: large !important;
}

.text-center {

  text-align: center !important;
}

.text-uppercase {

  text-transform: uppercase !important;
}

.no-underline {

  text-decoration: none !important;
}

.flex {

  display: flex !important;
}

.flex-row {

  direction: row !important ;
}

.flex-grow-1 {

  flex-grow: 1 !important;
}

.ml-5 {

  margin-left: 5rem;
}

.ml-3 {
  
  margin-left: 3rem;
}


.mr-3 {
  
  margin-right: 3rem;
}

.ml-1 {
  
  margin-left: 1rem;
}

.ml-n1 {
  
  margin-left: -1rem;
}

.mr-1 {
  
  margin-right: 1rem;
}

.mt-1-half {
  
  margin-top: 0.5rem;
}

.mt-1-quarter {
  
  margin-top: 0.75rem;
}

.mt-n1 {
  
  margin-top: -1rem;
}

.mt-0 {
  
  margin-top: 0px !important;
}

.mt-1 {
  
  margin-top: 1rem !important;
}

.mt-2 {
  
  margin-top: 2rem !important;
}

.mt-3 {
  
  margin-top: 3rem !important;
}

.mt-5 {
  
  margin-top: 5rem !important;
}

.mb-0 {
  
  margin-bottom: 0px !important;
}

.w-127 {

  width: 127%;
}

.w-full {

  width: 100%;
}

 .w-auto {

  width: auto;
 }
 
 .h-247px {

  height: 247px;
 }

.tracking-tight {

  letter-spacing: 1px !important;
}

.tracking-normal {

  letter-spacing: 2px !important;
}

.list-none {

  list-style-type: none !important;
}

.rounded {

  border-radius: 0.25rem !important
}


/* mobile device - sm */
@media (max-width: 740px) {

  .w-sm-319px {

    width: 319px !important;
  }

  .h-sm-auto {

    height: auto !important;
  }

  .w-sm-full {
    
    width: 100% !important;
  }

  .ml-sm-0 {

    margin-left: 0px !important;
  }

  .ml-sm-1 {
  
    margin-left: 1rem !important;
  }

  .text-sm-78px {
    font-size: 78px !important;
}

}